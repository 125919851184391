import { Link } from 'gatsby'
import styled from 'styled-components'
import { brandPrimary } from '../../styles/variables.scss'

export const MenuLi = styled.li`
  position: relative;
  @media (min-width: 992px) {
    &.has-submenu,
    &.has-subsection {
      > a {
        &:after {
          border-style: solid;
          border-width: 0.15em 0.15em 0 0;
          content: '';
          display: inline-block;
          height: 0.5em;
          left: 0.15em;
          position: relative;
          top: 0.35em;
          transform: rotate(135deg);
          vertical-align: top;
          width: 0.5em;
          margin-left: 5px;
          color: #a2a2a2;
        }
      }

      &:hover {
        .submenu.row {
          display: flex!important;
          min-width: 443px;
        }
        .submenu {
          display: inline-block !important;
          li {
            padding: 0;
            cursor:pointer;
            a {
              &:after {
                content: none;
                border: none;
              }

              width: 100%;

              padding: 12px 18px;
              border-bottom: 1px solid rgb(234, 234, 234);
              font-weight: 400!important;
              font-size: 1.1em;
              
}

            }
          }
        }
      }
    }
    &.has-subsection {
      .subsection {
        display: inline-block !important;
        li {
          padding: 0;
          a {
            &:after {
              content: none;
              border: none;
            }
            color: white;
            width: 100%;
            
          }
        }
        li ul li {
          list-style: none;
        }
      }
    }
  }
`

export const SectionTitle = styled(Link)`
  &&& {
    display: block;
    border: none;

    @media (max-width: 992px) {
      padding: 0.8rem 0;
      font-size: 1.5em;
    }
  }
`

export const SubMenu = styled.ul`
  display: block;
  position: inherit;
  box-shadow: none;

  li {
    @media (max-width: 992px) {
      background: transparent !important;
    }
  }
  a {
    @media (max-width: 992px) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    background: transparent !important;
  }

  @media (min-width: 992px) {
    display: none !important;
    position: absolute;
    border: 1px solid #e8e8e8b8;
  }

  font-size: 0.7937rem;
  line-height: 1.45rem;
  left: 0px;
  color: rgba(0, 0, 0, 0.88);
  list-style: none;
  margin: 0px;
  top: 100%;
  padding: 0.725rem 0px;
  padding-bottom: 0px;
  z-index: 100;
  overflow: hidden;
  min-width: 250px;
  @media (max-width: 992px) {
    background: transparent !important;
  }
`

export const SubSectionWrapper = styled.div`
  top: 100%;
  left: 0;
  z-index: 1;
  max-height: 600px;
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;
  border: none;
  border-radius: 4px;
  @media (max-width: 992px) {
    box-shadow: none !important;
    border: none !important;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 1px;
    width: 0px;
    background-color: #41a928;
    z-index: 101;
    @media (max-width: 992px) {
      background: transparent;
    }
  }

  &.off-right {
    right: 0;
    left: unset;
  }

  @media (max-width: 992px) {
    position: inherit;
    background: transparent;
    flex-wrap: no-wrap;
  }

  @media (max-width: 600px) {
    width: auto;
  }
`
export const SubSection = styled.ul`
  position: relative;
  display: block;
  box-shadow: none;

  li {
    &:hover {
      background: transparent !important;
    }
    @media (max-width: 992px) {
      background: transparent !important;
    }
  }
  a {
    @media (max-width: 992px) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    background: transparent !important;
  }

  @media (min-width: 992px) {
    display: none !important;
    margin: 0 0 -1px 0 !important;
  }

  font-size: 0.7937rem;
  line-height: 1.45rem;
  left: 0px;
  top: 100%;
  color: rgba(0, 0, 0, 0.88);
  list-style: none;
  margin: 0px;
  padding: 0.6rem 0px;
  z-index: 100;
  overflow: hidden;

  @media (max-width: 992px) {
    background: transparent !important;
  }
`

export const LogoSVG = styled.div`
  width: 110px;
  padding: 0;
  @media (max-width: 992px) {
    width: 200px;
  }
  .logo-text {
    font-size: 20px;
  }
`

export const PhoneNumberHeader = styled.a`
  text-decoration: none;
  font-weight: 700;
  font-size: 1.4em;
  color: #333 !important;
  @media (max-width: 992px) {
    font-size: 1.3em;
    font-weight: 600;
  }
  @media (max-width: 315px) {
    font-size: 1.2em !important;
  }
  @media (max-width: 303px) {
    font-size: 1em !important;
  }
  @media (max-width: 278px) {
    font-size: 0.9em !important;
  }
`
export const HeaderOuterContainer = styled.div`
  background: #4dbc15;
  background: #ffffff;

  z-index: 100;
  position: relative;
  padding: 0 15px !important;

  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
`

export const NavOuterContainer = styled.nav`
  background: #f8fafc;
  background: #fff;
  border-top: 1px solid whitesmoke;
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    &:hover {
      li {
        a {
          opacity: 0.8;
        }
        &:hover {
          a {
            opacity: 1;
          }
        }
      }
    }
    li {
      list-style-type: none;
      margin: 0;
      padding: 0 18px;
      &:first-child {
        padding-left: 0;
      }
      a {
        color: #333;
        text-decoration: none;
        padding: 18px 0;
        padding-bottom: 16px;
        transition: 0.2s ease all;
        font-size: 17px;
        font-weight: 600;
        display: inline-block;
        border-bottom: 4px solid transparent;
        &:hover {
          opacity: 1;
          &:before {
            opacity: 1;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
`
export const NavigationMobileUL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 1em !important;

  li {
    font-weight: 600;
    a {
      color: #333;
      text-decoration: none;
      font-size: 1.25em;
    }
    &.active {
      color: green;
    }
  }
`
export const MobileSecondaryCTA = styled.div`
  background: #f8f8f8;
  padding: 0.5em 0;
`
export const StickyContainer = styled.div`
  z-index: 10000;
  position: sticky;
  top: 0;
`

export const LogoContainer = styled.div`
  a {
    text-decoration: none;
    font-size: 1.25em;
    font-weight: 600;
    color: ${brandPrimary};
    padding: 0.5em 0;
  }
`

export const MenuMobile = styled.div``

export const MobileIconBurger = styled.button`
  background: none;
  border: none;
  line-height: 0;
  cursor: pointer;
  touch-action: manipulate;
  outline: none;
  &:hover {
    opacity: 0.8;
  }
  color: #fff;
`
export const CTASectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cta-button {  @media(max-width:320px){
      padding:.3em .7em;}
  }
}
`
export const SiteTagline = styled.div`
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`
