import React from 'react'
import styled from 'styled-components'

export default class Logo extends React.Component {
  render() {
    const LogoContainer = styled.div`
    .ab7eff39-6b8f-4657-b252-8c7b5bfbe0fd, .green{
      fill:#41a928!important;
    }
    .b696259e-0495-47c8-a801-747f4cd1fb3e {
      fill:#0c191c;
    }


 
      .b696259e-0495-47c8-a801-747f4cd1fb3e {
        fill: ${
          this.props && this.props.color == 'white' ? 'white' : '#0c191c'
        };
      }

      .b696259e-0495-47c8-a801-747f4cd1fb3e {fill: ${
        this.props && this.props.color == 'white' ? '#fff' : '#0c191c'
      };
     
    `

    if (this.props.horizontal) {
      return (
        <LogoContainer>
          <svg
            viewBox="0 0 1179 428"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M328.023 87.8311C322.235 90.9376 316.8 93.6912 311.647 96.939C305.44 100.705 299.786 105.315 294.847 110.636C294.847 110.636 294.07 111.201 294.211 111.766C294.352 112.331 294.847 112.119 295.27 112.119C301.129 112.119 306.917 113.39 312.776 113.884C321.019 114.711 329.29 112.611 336.141 107.953C343.436 102.806 349.234 95.8134 352.941 87.6899C355.156 83.417 356.975 78.9504 358.376 74.3458C359.011 72.157 358.941 71.9452 356.682 71.3804C353.6 70.5939 350.461 70.051 347.294 69.7565C339.689 68.9977 332.009 69.7659 324.705 72.0158C314.792 75.3506 306.353 82.043 300.847 90.9376C298.792 94.0344 297.088 97.3495 295.764 100.822C295.764 101.316 294.988 101.952 295.764 102.375C296.541 102.799 296.752 102.375 297.176 101.881C303.9 97.478 310.984 93.6517 318.352 90.4434C320.93 89.2783 323.629 88.4022 326.4 87.8311C327.146 87.6863 327.912 87.6863 328.658 87.8311"
              fill="#4DBC15"
            />
            <path
              d="M248.753 286.156H213.741V305.855H248.753V286.156Z"
              fill="#4DBC15"
            />
            <path
              d="M207.105 286.156H172.094V305.855H207.105V286.156Z"
              fill="#4DBC15"
            />
            <path
              d="M248.753 311.786H213.741V331.484H248.753V311.786Z"
              fill="#4DBC15"
            />
            <path
              d="M207.105 311.786H172.094V331.484H207.105V311.786Z"
              fill="#4DBC15"
            />
            <path
              d="M148.66 365.727H94.3772V51.8937H148.801V178.98H246.989V234.687L229.907 234.969L211.412 214.706L121.977 307.974L148.66 307.832V365.727Z"
              fill="#4DBC15"
            />
            <path
              d="M349.624 51.7524C349.967 44.3652 348.633 36.9962 345.724 30.1978C342.814 23.3993 338.404 17.3478 332.824 12.4967C326.376 6.5887 318.406 2.60236 309.812 0.988257C302.015 -0.580856 293.957 -0.26619 286.306 1.90611C278.992 3.89258 272.247 7.56841 266.612 12.6379C259.875 18.4991 254.871 26.0926 252.141 34.5956C249.84 41.9416 249.213 49.7099 250.306 57.33C251.509 66.3682 254.433 75.092 258.918 83.0298C262.13 89.0477 266.44 94.4116 271.624 98.8451C273.404 100.247 274.82 102.056 275.754 104.12C276.688 106.185 277.111 108.444 276.988 110.706C276.988 114.943 276.635 114.66 280.871 114.66H286.518C286.965 114.678 287.412 114.599 287.826 114.429C288.24 114.258 288.613 114 288.918 113.672C291.388 110.918 293.788 108.235 296.541 105.694C296.894 105.694 297.459 105.058 297.247 104.493C297.035 103.929 296.33 104.14 295.835 104.14H288.777C288.255 104.202 287.729 104.06 287.308 103.745C286.888 103.43 286.605 102.964 286.518 102.446C285.08 98.0804 282.467 94.196 278.965 91.2199C273.029 85.864 268.393 79.2232 265.412 71.8038C263.363 67.0173 261.939 61.9865 261.177 56.8358C260.509 52.5312 260.509 48.1493 261.177 43.8447C262.247 37.3394 264.915 31.2015 268.941 25.982C272.202 21.7869 276.285 18.3031 280.941 15.7444C288.083 11.6648 296.361 10.0286 304.518 11.0846C313.615 12.1344 322.033 16.4192 328.235 23.1578C333.69 28.7169 337.374 35.7683 338.824 43.4211C339.737 48.4398 339.737 53.5823 338.824 58.6009C338.458 60.9914 337.916 63.3514 337.2 65.6613C337.2 66.8616 337.2 67.3558 338.471 67.3558C341.078 67.1441 343.699 67.1441 346.306 67.3558C347.435 67.3558 348 66.9322 348.282 65.8025C349.62 61.2159 350.286 56.4595 350.259 51.6817"
              fill="#0C191C"
            />
            <path
              d="M299.647 120.026H280.517C278.4 120.026 278.329 120.026 278.258 122.215V124.545C278.18 125.519 278.305 126.498 278.625 127.421C278.944 128.344 279.452 129.19 280.115 129.907C280.779 130.623 281.583 131.195 282.479 131.584C283.374 131.974 284.341 132.173 285.317 132.17C294.988 132.17 304.588 132.17 314.258 132.17C315.142 132.227 316.027 132.107 316.863 131.817C317.699 131.527 318.468 131.072 319.126 130.48C319.784 129.888 320.317 129.171 320.693 128.37C321.069 127.568 321.281 126.7 321.317 125.816C321.317 124.616 321.317 123.486 321.317 122.286C321.317 121.085 321.317 120.026 319.058 120.026H299.647Z"
              fill="#0C191C"
            />
            <path
              d="M299.718 135.135H287.506C286.024 135.135 285.459 135.63 285.389 137.183C285.147 139.348 285.671 141.53 286.871 143.348C288.07 145.167 289.868 146.508 291.953 147.138C292.728 147.207 293.508 147.207 294.283 147.138C297.953 147.138 301.342 147.138 305.294 147.138C306.646 147.101 307.973 146.771 309.184 146.169C310.395 145.568 311.461 144.71 312.307 143.655C313.153 142.6 313.76 141.374 314.085 140.061C314.41 138.749 314.445 137.381 314.189 136.053C314.189 134.853 313.13 134.853 312.283 134.853H299.718"
              fill="#0C191C"
            />
            <path
              d="M282.706 294.276L272.964 283.474V150.527H327.6V365.939H272.964V308.044H282.706V356.266H317.788V159.847H283.906L282.706 294.276Z"
              fill="#0C191C"
            />
            <path
              d="M499.32 134V218H475.56V185.24H443.16V218H419.4V134H443.16V165.56H475.56V134H499.32Z"
              fill="#4DBC15"
            />
            <path
              d="M558.44 219.68C549.56 219.68 541.56 217.8 534.44 214.04C527.4 210.28 521.84 205.08 517.76 198.44C513.76 191.8 511.76 184.32 511.76 176C511.76 167.68 513.76 160.2 517.76 153.56C521.84 146.92 527.4 141.72 534.44 137.96C541.56 134.2 549.56 132.32 558.44 132.32C567.32 132.32 575.28 134.2 582.32 137.96C589.44 141.72 595 146.92 599 153.56C603.08 160.2 605.12 167.68 605.12 176C605.12 184.32 603.08 191.8 599 198.44C595 205.08 589.44 210.28 582.32 214.04C575.28 217.8 567.32 219.68 558.44 219.68ZM558.44 200C562.68 200 566.52 199 569.96 197C573.4 195 576.12 192.2 578.12 188.6C580.12 184.92 581.12 180.72 581.12 176C581.12 171.28 580.12 167.12 578.12 163.52C576.12 159.84 573.4 157 569.96 155C566.52 153 562.68 152 558.44 152C554.2 152 550.36 153 546.92 155C543.48 157 540.76 159.84 538.76 163.52C536.76 167.12 535.76 171.28 535.76 176C535.76 180.72 536.76 184.92 538.76 188.6C540.76 192.2 543.48 195 546.92 197C550.36 199 554.2 200 558.44 200Z"
              fill="#4DBC15"
            />
            <path
              d="M656.324 219.68C643.844 219.68 634.124 216.28 627.164 209.48C620.284 202.68 616.844 193.04 616.844 180.56V134H640.604V179.84C640.604 193.28 645.924 200 656.564 200C667.124 200 672.404 193.28 672.404 179.84V134H695.804V180.56C695.804 193.04 692.324 202.68 685.364 209.48C678.484 216.28 668.804 219.68 656.324 219.68Z"
              fill="#4DBC15"
            />
            <path
              d="M741.42 219.68C734.62 219.68 728.02 218.84 721.62 217.16C715.22 215.48 710.02 213.24 706.02 210.44L713.82 192.92C717.58 195.4 721.94 197.4 726.9 198.92C731.86 200.44 736.74 201.2 741.54 201.2C750.66 201.2 755.22 198.92 755.22 194.36C755.22 191.96 753.9 190.2 751.26 189.08C748.7 187.88 744.54 186.64 738.78 185.36C732.46 184 727.18 182.56 722.94 181.04C718.7 179.44 715.06 176.92 712.02 173.48C708.98 170.04 707.46 165.4 707.46 159.56C707.46 154.44 708.86 149.84 711.66 145.76C714.46 141.6 718.62 138.32 724.14 135.92C729.74 133.52 736.58 132.32 744.66 132.32C750.18 132.32 755.62 132.96 760.98 134.24C766.34 135.44 771.06 137.24 775.14 139.64L767.82 157.28C759.82 152.96 752.06 150.8 744.54 150.8C739.82 150.8 736.38 151.52 734.22 152.96C732.06 154.32 730.98 156.12 730.98 158.36C730.98 160.6 732.26 162.28 734.82 163.4C737.38 164.52 741.5 165.68 747.18 166.88C753.58 168.24 758.86 169.72 763.02 171.32C767.26 172.84 770.9 175.32 773.94 178.76C777.06 182.12 778.62 186.72 778.62 192.56C778.62 197.6 777.22 202.16 774.42 206.24C771.62 210.32 767.42 213.6 761.82 216.08C756.22 218.48 749.42 219.68 741.42 219.68Z"
              fill="#4DBC15"
            />
            <path
              d="M857.035 199.64V218H789.595V134H855.475V152.36H813.115V166.52H850.435V184.28H813.115V199.64H857.035Z"
              fill="#4DBC15"
            />
            <path d="M870.22 134H893.98V218H870.22V134Z" fill="#4DBC15" />
            <path
              d="M910.885 134H950.604C959.804 134 967.964 135.72 975.084 139.16C982.204 142.6 987.725 147.48 991.645 153.8C995.565 160.12 997.524 167.52 997.524 176C997.524 184.48 995.565 191.88 991.645 198.2C987.725 204.52 982.204 209.4 975.084 212.84C967.964 216.28 959.804 218 950.604 218H910.885V134ZM949.644 199.04C956.844 199.04 962.605 197 966.924 192.92C971.325 188.84 973.524 183.2 973.524 176C973.524 168.8 971.325 163.16 966.924 159.08C962.605 155 956.844 152.96 949.644 152.96H934.644V199.04H949.644Z"
              fill="#4DBC15"
            />
            <path
              d="M1077.47 199.64V218H1010.03V134H1075.91V152.36H1033.55V166.52H1070.87V184.28H1033.55V199.64H1077.47Z"
              fill="#4DBC15"
            />
            <path
              d="M1146.93 201.68H1111.41L1104.81 218H1080.57L1117.65 134H1141.05L1178.25 218H1153.53L1146.93 201.68ZM1139.97 184.16L1129.17 157.28L1118.37 184.16H1139.97Z"
              fill="#4DBC15"
            />
            <path
              d="M439.006 310.536C434.316 310.536 430.073 309.509 426.276 307.454C422.524 305.355 419.576 302.496 417.432 298.878C415.288 295.215 414.216 291.106 414.216 286.55C414.216 281.994 415.288 277.907 417.432 274.289C419.576 270.626 422.546 267.768 426.343 265.713C430.14 263.614 434.383 262.564 439.073 262.564C442.736 262.564 446.086 263.189 449.123 264.44C452.16 265.646 454.751 267.455 456.895 269.867L452.54 274.088C449.011 270.381 444.612 268.527 439.341 268.527C435.857 268.527 432.708 269.309 429.894 270.872C427.08 272.435 424.869 274.602 423.261 277.371C421.698 280.096 420.916 283.155 420.916 286.55C420.916 289.945 421.698 293.027 423.261 295.796C424.869 298.521 427.08 300.665 429.894 302.228C432.708 303.791 435.857 304.573 439.341 304.573C444.567 304.573 448.967 302.697 452.54 298.945L456.895 303.166C454.751 305.578 452.138 307.409 449.056 308.66C446.019 309.911 442.669 310.536 439.006 310.536Z"
              fill="black"
            />
            <path
              d="M497.501 274.49V310H491.404V304.64C490.108 306.471 488.389 307.901 486.245 308.928C484.145 309.911 481.845 310.402 479.344 310.402C474.609 310.402 470.879 309.107 468.155 306.516C465.43 303.881 464.068 300.017 464.068 294.925V274.49H470.5V294.188C470.5 297.627 471.326 300.24 472.979 302.027C474.631 303.769 476.999 304.64 480.081 304.64C483.475 304.64 486.155 303.613 488.121 301.558C490.086 299.503 491.069 296.6 491.069 292.848V274.49H497.501Z"
              fill="black"
            />
            <path
              d="M519.929 310.402C517.026 310.402 514.234 310.022 511.554 309.263C508.874 308.459 506.775 307.454 505.257 306.248L507.937 301.156C509.5 302.273 511.398 303.166 513.632 303.836C515.865 304.506 518.12 304.841 520.398 304.841C526.026 304.841 528.84 303.233 528.84 300.017C528.84 298.945 528.461 298.096 527.701 297.471C526.942 296.846 525.982 296.399 524.821 296.131C523.704 295.818 522.096 295.483 519.996 295.126C517.138 294.679 514.793 294.166 512.962 293.585C511.175 293.004 509.634 292.022 508.339 290.637C507.043 289.252 506.396 287.309 506.396 284.808C506.396 281.592 507.735 279.024 510.415 277.103C513.095 275.138 516.691 274.155 521.202 274.155C523.57 274.155 525.937 274.445 528.305 275.026C530.672 275.607 532.615 276.388 534.133 277.371L531.386 282.463C528.483 280.587 525.066 279.649 521.135 279.649C518.411 279.649 516.334 280.096 514.905 280.989C513.475 281.882 512.76 283.066 512.76 284.54C512.76 285.701 513.162 286.617 513.966 287.287C514.77 287.957 515.753 288.448 516.914 288.761C518.12 289.074 519.795 289.431 521.939 289.833C524.798 290.324 527.098 290.86 528.84 291.441C530.627 291.977 532.146 292.915 533.396 294.255C534.647 295.595 535.272 297.471 535.272 299.883C535.272 303.099 533.888 305.667 531.118 307.588C528.394 309.464 524.664 310.402 519.929 310.402Z"
              fill="black"
            />
            <path
              d="M563.277 307.923C562.339 308.727 561.178 309.352 559.793 309.799C558.408 310.201 556.979 310.402 555.505 310.402C551.932 310.402 549.162 309.442 547.197 307.521C545.232 305.6 544.249 302.853 544.249 299.28V279.783H538.219V274.49H544.249V266.718H550.681V274.49H560.865V279.783H550.681V299.012C550.681 300.933 551.15 302.407 552.088 303.434C553.071 304.461 554.455 304.975 556.242 304.975C558.207 304.975 559.882 304.417 561.267 303.3L563.277 307.923Z"
              fill="black"
            />
            <path
              d="M585.084 310.402C581.556 310.402 578.384 309.62 575.57 308.057C572.756 306.494 570.545 304.35 568.937 301.625C567.374 298.856 566.592 295.729 566.592 292.245C566.592 288.761 567.374 285.657 568.937 282.932C570.545 280.163 572.756 278.019 575.57 276.5C578.384 274.937 581.556 274.155 585.084 274.155C588.613 274.155 591.762 274.937 594.531 276.5C597.345 278.019 599.534 280.163 601.097 282.932C602.705 285.657 603.509 288.761 603.509 292.245C603.509 295.729 602.705 298.856 601.097 301.625C599.534 304.35 597.345 306.494 594.531 308.057C591.762 309.62 588.613 310.402 585.084 310.402ZM585.084 304.774C587.362 304.774 589.395 304.26 591.181 303.233C593.013 302.161 594.442 300.687 595.469 298.811C596.497 296.89 597.01 294.702 597.01 292.245C597.01 289.788 596.497 287.622 595.469 285.746C594.442 283.825 593.013 282.351 591.181 281.324C589.395 280.297 587.362 279.783 585.084 279.783C582.806 279.783 580.752 280.297 578.92 281.324C577.134 282.351 575.704 283.825 574.632 285.746C573.605 287.622 573.091 289.788 573.091 292.245C573.091 294.702 573.605 296.89 574.632 298.811C575.704 300.687 577.134 302.161 578.92 303.233C580.752 304.26 582.806 304.774 585.084 304.774Z"
              fill="black"
            />
            <path
              d="M656.826 274.155C661.292 274.155 664.821 275.45 667.412 278.041C670.047 280.632 671.365 284.473 671.365 289.565V310H664.933V290.302C664.933 286.863 664.129 284.272 662.521 282.53C660.957 280.788 658.702 279.917 655.754 279.917C652.493 279.917 649.902 280.944 647.982 282.999C646.061 285.009 645.101 287.912 645.101 291.709V310H638.669V290.302C638.669 286.863 637.865 284.272 636.257 282.53C634.693 280.788 632.438 279.917 629.49 279.917C626.229 279.917 623.638 280.944 621.718 282.999C619.797 285.009 618.837 287.912 618.837 291.709V310H612.405V274.49H618.569V279.783C619.864 277.952 621.561 276.567 623.661 275.629C625.76 274.646 628.15 274.155 630.83 274.155C633.599 274.155 636.056 274.713 638.2 275.83C640.344 276.947 641.996 278.577 643.158 280.721C644.498 278.666 646.351 277.058 648.719 275.897C651.131 274.736 653.833 274.155 656.826 274.155Z"
              fill="black"
            />
            <path
              d="M742.465 263.1V310H735.765V289.096H708.831V310H702.131V263.1H708.831V283.267H735.765V263.1H742.465Z"
              fill="black"
            />
            <path
              d="M770.774 310.402C767.245 310.402 764.074 309.62 761.26 308.057C758.446 306.494 756.235 304.35 754.627 301.625C753.063 298.856 752.282 295.729 752.282 292.245C752.282 288.761 753.063 285.657 754.627 282.932C756.235 280.163 758.446 278.019 761.26 276.5C764.074 274.937 767.245 274.155 770.774 274.155C774.303 274.155 777.451 274.937 780.221 276.5C783.035 278.019 785.224 280.163 786.787 282.932C788.395 285.657 789.199 288.761 789.199 292.245C789.199 295.729 788.395 298.856 786.787 301.625C785.224 304.35 783.035 306.494 780.221 308.057C777.451 309.62 774.303 310.402 770.774 310.402ZM770.774 304.774C773.052 304.774 775.084 304.26 776.871 303.233C778.702 302.161 780.132 300.687 781.159 298.811C782.186 296.89 782.7 294.702 782.7 292.245C782.7 289.788 782.186 287.622 781.159 285.746C780.132 283.825 778.702 282.351 776.871 281.324C775.084 280.297 773.052 279.783 770.774 279.783C768.496 279.783 766.441 280.297 764.61 281.324C762.823 282.351 761.394 283.825 760.322 285.746C759.295 287.622 758.781 289.788 758.781 292.245C758.781 294.702 759.295 296.89 760.322 298.811C761.394 300.687 762.823 302.161 764.61 303.233C766.441 304.26 768.496 304.774 770.774 304.774Z"
              fill="black"
            />
            <path
              d="M842.515 274.155C846.982 274.155 850.51 275.45 853.101 278.041C855.736 280.632 857.054 284.473 857.054 289.565V310H850.622V290.302C850.622 286.863 849.818 284.272 848.21 282.53C846.647 280.788 844.391 279.917 841.443 279.917C838.182 279.917 835.592 280.944 833.671 282.999C831.75 285.009 830.79 287.912 830.79 291.709V310H824.358V290.302C824.358 286.863 823.554 284.272 821.946 282.53C820.383 280.788 818.127 279.917 815.179 279.917C811.918 279.917 809.328 280.944 807.407 282.999C805.486 285.009 804.526 287.912 804.526 291.709V310H798.094V274.49H804.258V279.783C805.553 277.952 807.251 276.567 809.35 275.629C811.449 274.646 813.839 274.155 816.519 274.155C819.288 274.155 821.745 274.713 823.889 275.83C826.033 276.947 827.686 278.577 828.847 280.721C830.187 278.666 832.041 277.058 834.408 275.897C836.82 274.736 839.522 274.155 842.515 274.155Z"
              fill="black"
            />
            <path
              d="M901.178 292.446C901.178 292.937 901.134 293.585 901.044 294.389H872.234C872.636 297.516 873.999 300.039 876.321 301.96C878.689 303.836 881.614 304.774 885.098 304.774C889.342 304.774 892.759 303.345 895.349 300.486L898.9 304.64C897.292 306.516 895.282 307.945 892.87 308.928C890.503 309.911 887.845 310.402 884.897 310.402C881.145 310.402 877.818 309.643 874.914 308.124C872.011 306.561 869.755 304.394 868.147 301.625C866.584 298.856 865.802 295.729 865.802 292.245C865.802 288.806 866.562 285.701 868.08 282.932C869.644 280.163 871.765 278.019 874.445 276.5C877.17 274.937 880.23 274.155 883.624 274.155C887.019 274.155 890.034 274.937 892.669 276.5C895.349 278.019 897.426 280.163 898.9 282.932C900.419 285.701 901.178 288.873 901.178 292.446ZM883.624 279.582C880.542 279.582 877.952 280.52 875.852 282.396C873.798 284.272 872.592 286.729 872.234 289.766H895.014C894.657 286.773 893.429 284.339 891.329 282.463C889.275 280.542 886.706 279.582 883.624 279.582Z"
              fill="black"
            />
            <path
              d="M960.598 285.679C963.323 286.438 965.467 287.823 967.03 289.833C968.593 291.798 969.375 294.3 969.375 297.337C969.375 301.402 967.834 304.528 964.752 306.717C961.715 308.906 957.27 310 951.419 310H929.041V263.1H950.079C955.439 263.1 959.571 264.172 962.474 266.316C965.422 268.46 966.896 271.43 966.896 275.227C966.896 277.684 966.315 279.805 965.154 281.592C964.037 283.379 962.519 284.741 960.598 285.679ZM935.741 268.527V283.535H949.476C952.915 283.535 955.551 282.91 957.382 281.659C959.258 280.364 960.196 278.488 960.196 276.031C960.196 273.574 959.258 271.721 957.382 270.47C955.551 269.175 952.915 268.527 949.476 268.527H935.741ZM951.151 304.573C954.948 304.573 957.806 303.948 959.727 302.697C961.648 301.446 962.608 299.481 962.608 296.801C962.608 291.575 958.789 288.962 951.151 288.962H935.741V304.573H951.151Z"
              fill="black"
            />
            <path
              d="M1011.91 274.49V310H1005.81V304.64C1004.52 306.471 1002.8 307.901 1000.65 308.928C998.554 309.911 996.253 310.402 993.752 310.402C989.017 310.402 985.288 309.107 982.563 306.516C979.838 303.881 978.476 300.017 978.476 294.925V274.49H984.908V294.188C984.908 297.627 985.734 300.24 987.387 302.027C989.04 303.769 991.407 304.64 994.489 304.64C997.884 304.64 1000.56 303.613 1002.53 301.558C1004.49 299.503 1005.48 296.6 1005.48 292.848V274.49H1011.91Z"
              fill="black"
            />
            <path
              d="M1024.15 274.49H1030.59V310H1024.15V274.49ZM1027.37 267.656C1026.12 267.656 1025.07 267.254 1024.22 266.45C1023.42 265.646 1023.01 264.663 1023.01 263.502C1023.01 262.341 1023.42 261.358 1024.22 260.554C1025.07 259.705 1026.12 259.281 1027.37 259.281C1028.62 259.281 1029.65 259.683 1030.45 260.487C1031.3 261.246 1031.72 262.207 1031.72 263.368C1031.72 264.574 1031.3 265.601 1030.45 266.45C1029.65 267.254 1028.62 267.656 1027.37 267.656Z"
              fill="black"
            />
            <path
              d="M1042.87 260.286H1049.3V310H1042.87V260.286Z"
              fill="black"
            />
            <path
              d="M1095.08 260.286V310H1088.92V304.372C1087.49 306.337 1085.68 307.834 1083.49 308.861C1081.3 309.888 1078.89 310.402 1076.25 310.402C1072.81 310.402 1069.73 309.643 1067.01 308.124C1064.28 306.605 1062.14 304.484 1060.57 301.759C1059.06 298.99 1058.3 295.818 1058.3 292.245C1058.3 288.672 1059.06 285.523 1060.57 282.798C1062.14 280.073 1064.28 277.952 1067.01 276.433C1069.73 274.914 1072.81 274.155 1076.25 274.155C1078.8 274.155 1081.14 274.646 1083.29 275.629C1085.43 276.567 1087.22 277.974 1088.65 279.85V260.286H1095.08ZM1076.79 304.774C1079.02 304.774 1081.05 304.26 1082.89 303.233C1084.72 302.161 1086.15 300.687 1087.17 298.811C1088.2 296.89 1088.71 294.702 1088.71 292.245C1088.71 289.788 1088.2 287.622 1087.17 285.746C1086.15 283.825 1084.72 282.351 1082.89 281.324C1081.05 280.297 1079.02 279.783 1076.79 279.783C1074.51 279.783 1072.46 280.297 1070.62 281.324C1068.84 282.351 1067.41 283.825 1066.34 285.746C1065.31 287.622 1064.8 289.788 1064.8 292.245C1064.8 294.702 1065.31 296.89 1066.34 298.811C1067.41 300.687 1068.84 302.161 1070.62 303.233C1072.46 304.26 1074.51 304.774 1076.79 304.774Z"
              fill="black"
            />
            <path
              d="M1139.34 292.446C1139.34 292.937 1139.3 293.585 1139.21 294.389H1110.4C1110.8 297.516 1112.16 300.039 1114.49 301.96C1116.85 303.836 1119.78 304.774 1123.26 304.774C1127.51 304.774 1130.92 303.345 1133.51 300.486L1137.06 304.64C1135.46 306.516 1133.45 307.945 1131.03 308.928C1128.67 309.911 1126.01 310.402 1123.06 310.402C1119.31 310.402 1115.98 309.643 1113.08 308.124C1110.18 306.561 1107.92 304.394 1106.31 301.625C1104.75 298.856 1103.97 295.729 1103.97 292.245C1103.97 288.806 1104.73 285.701 1106.24 282.932C1107.81 280.163 1109.93 278.019 1112.61 276.5C1115.33 274.937 1118.39 274.155 1121.79 274.155C1125.18 274.155 1128.2 274.937 1130.83 276.5C1133.51 278.019 1135.59 280.163 1137.06 282.932C1138.58 285.701 1139.34 288.873 1139.34 292.446ZM1121.79 279.582C1118.71 279.582 1116.12 280.52 1114.02 282.396C1111.96 284.272 1110.76 286.729 1110.4 289.766H1133.18C1132.82 286.773 1131.59 284.339 1129.49 282.463C1127.44 280.542 1124.87 279.582 1121.79 279.582Z"
              fill="black"
            />
            <path
              d="M1154.44 280.453C1155.55 278.398 1157.21 276.835 1159.4 275.763C1161.58 274.691 1164.24 274.155 1167.37 274.155V280.386C1167.01 280.341 1166.52 280.319 1165.89 280.319C1162.41 280.319 1159.66 281.369 1157.65 283.468C1155.69 285.523 1154.71 288.471 1154.71 292.312V310H1148.27V274.49H1154.44V280.453Z"
              fill="black"
            />
          </svg>
        </LogoContainer>
      )
    }
    return (
      <LogoContainer>
        <svg
          viewBox="0 0 659 707"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M490.776 117.792C483.016 121.958 475.729 125.651 468.82 130.007C460.499 135.058 452.919 141.24 446.297 148.376C446.297 148.376 445.256 149.134 445.445 149.891C445.634 150.649 446.297 150.365 446.864 150.365C454.719 150.365 462.479 152.069 470.334 152.732C481.385 153.841 492.474 151.026 501.659 144.778C511.44 137.876 519.213 128.497 524.183 117.603C527.152 111.872 529.592 105.882 531.47 99.7067C532.321 96.7714 532.227 96.4873 529.198 95.7298C525.066 94.675 520.858 93.9469 516.612 93.552C506.416 92.5343 496.12 93.5645 486.328 96.582C473.037 101.054 461.723 110.03 454.341 121.958C451.587 126.112 449.301 130.558 447.527 135.215C447.527 135.878 446.486 136.73 447.527 137.298C448.568 137.866 448.852 137.298 449.42 136.635C458.434 130.73 467.931 125.598 477.811 121.296C481.267 119.733 484.885 118.558 488.599 117.792C489.599 117.598 490.627 117.598 491.628 117.792"
            fill="#4DBC15"
          />
          <path
            d="M384.5 383.771H337.56V410.189H384.5V383.771Z"
            fill="#4DBC15"
          />
          <path
            d="M328.663 383.771H281.724V410.189H328.663V383.771Z"
            fill="#4DBC15"
          />
          <path
            d="M384.5 418.143H337.56V444.561H384.5V418.143Z"
            fill="#4DBC15"
          />
          <path
            d="M328.663 418.143H281.724V444.561H328.663V418.143Z"
            fill="#4DBC15"
          />
          <path
            d="M250.306 490.485H177.53V69.5957H250.495V240.035H382.135V314.744L359.233 315.122L334.438 287.947L214.533 413.03L250.306 412.841V490.485Z"
            fill="#4DBC15"
          />
          <path
            d="M519.736 69.4062C520.195 59.4991 518.408 49.6164 514.507 40.4989C510.607 31.3813 504.694 23.2654 497.212 16.7595C488.569 8.83623 477.882 3.49008 466.361 1.32537C455.908 -0.779005 445.104 -0.356999 434.847 2.55631C425.041 5.22041 415.997 10.1502 408.443 16.9489C399.411 24.8095 392.702 34.9934 389.043 46.397C385.957 56.2488 385.116 66.667 386.582 76.8866C388.195 89.0078 392.114 100.707 398.128 111.353C402.435 119.424 408.212 126.617 415.162 132.563C417.549 134.443 419.448 136.869 420.7 139.638C421.952 142.407 422.519 145.436 422.355 148.471C422.355 154.152 421.881 153.773 427.56 153.773H435.131C435.731 153.798 436.329 153.692 436.884 153.463C437.44 153.234 437.939 152.888 438.348 152.448C441.661 148.755 444.878 145.157 448.569 141.748C449.042 141.748 449.799 140.896 449.515 140.138C449.231 139.381 448.285 139.665 447.623 139.665H438.159C437.459 139.748 436.754 139.558 436.191 139.135C435.627 138.712 435.247 138.088 435.131 137.392C433.203 131.538 429.699 126.328 425.004 122.337C417.046 115.154 410.831 106.248 406.834 96.2976C404.087 89.8783 402.179 83.1314 401.156 76.2237C400.261 70.4507 400.261 64.5741 401.156 58.8011C402.591 50.0766 406.168 41.845 411.566 34.845C415.937 29.2189 421.412 24.5466 427.654 21.1152C437.229 15.6439 448.328 13.4496 459.263 14.8658C471.46 16.2737 482.745 22.0201 491.061 31.0575C498.374 38.5128 503.313 47.9696 505.256 58.233C506.481 64.9636 506.481 71.8603 505.256 78.5909C504.767 81.7969 504.039 84.9619 503.08 88.0598C503.08 89.6695 503.08 90.3323 504.783 90.3323C508.279 90.0484 511.792 90.0484 515.288 90.3323C516.802 90.3323 517.559 89.7642 517.938 88.2491C519.731 82.0979 520.624 75.719 520.587 69.3115"
            fill="#0C191C"
          />
          <path
            d="M452.733 160.97H427.086C424.247 160.97 424.152 160.97 424.058 163.905V167.03C423.953 168.336 424.12 169.649 424.549 170.887C424.977 172.124 425.658 173.26 426.547 174.221C427.436 175.182 428.515 175.948 429.716 176.47C430.916 176.993 432.212 177.261 433.521 177.256C446.487 177.256 459.357 177.256 472.322 177.256C473.506 177.333 474.694 177.172 475.814 176.783C476.935 176.394 477.967 175.784 478.848 174.99C479.73 174.196 480.444 173.234 480.949 172.159C481.453 171.085 481.738 169.92 481.786 168.734C481.786 167.125 481.786 165.61 481.786 164C481.786 162.39 481.786 160.97 478.758 160.97H452.733Z"
            fill="#0C191C"
          />
          <path
            d="M452.828 181.233H436.456C434.468 181.233 433.711 181.896 433.617 183.979C433.293 186.883 433.996 189.808 435.604 192.247C437.211 194.687 439.623 196.485 442.418 197.33C443.457 197.423 444.502 197.423 445.541 197.33C450.462 197.33 455.005 197.33 460.304 197.33C462.116 197.281 463.896 196.837 465.519 196.031C467.143 195.224 468.571 194.074 469.706 192.659C470.84 191.245 471.654 189.6 472.089 187.84C472.525 186.079 472.572 184.245 472.228 182.464C472.228 180.854 470.809 180.854 469.673 180.854H452.828"
            fill="#0C191C"
          />
          <path
            d="M430.019 394.66L416.959 380.173V201.875H490.208V490.769H416.959V413.124H430.019V477.796H477.054V214.374H431.628L430.019 394.66Z"
            fill="#0C191C"
          />

          {!this.props.hideLogoText ? (
            <>
              <path
                d="M72.6 554V624H52.8V596.7H25.8V624H6V554H25.8V580.3H52.8V554H72.6Z"
                fill="#4DBC15"
              />
              <path
                d="M121.866 625.4C114.466 625.4 107.8 623.833 101.866 620.7C95.9997 617.567 91.3664 613.233 87.9664 607.7C84.6331 602.167 82.9664 595.933 82.9664 589C82.9664 582.067 84.6331 575.833 87.9664 570.3C91.3664 564.767 95.9997 560.433 101.866 557.3C107.8 554.167 114.466 552.6 121.866 552.6C129.266 552.6 135.9 554.167 141.766 557.3C147.7 560.433 152.333 564.767 155.666 570.3C159.066 575.833 160.766 582.067 160.766 589C160.766 595.933 159.066 602.167 155.666 607.7C152.333 613.233 147.7 617.567 141.766 620.7C135.9 623.833 129.266 625.4 121.866 625.4ZM121.866 609C125.4 609 128.6 608.167 131.466 606.5C134.333 604.833 136.6 602.5 138.266 599.5C139.933 596.433 140.766 592.933 140.766 589C140.766 585.067 139.933 581.6 138.266 578.6C136.6 575.533 134.333 573.167 131.466 571.5C128.6 569.833 125.4 569 121.866 569C118.333 569 115.133 569.833 112.266 571.5C109.4 573.167 107.133 575.533 105.466 578.6C103.8 581.6 102.966 585.067 102.966 589C102.966 592.933 103.8 596.433 105.466 599.5C107.133 602.5 109.4 604.833 112.266 606.5C115.133 608.167 118.333 609 121.866 609Z"
                fill="#4DBC15"
              />
              <path
                d="M203.437 625.4C193.037 625.4 184.937 622.567 179.137 616.9C173.403 611.233 170.537 603.2 170.537 592.8V554H190.337V592.2C190.337 603.4 194.77 609 203.637 609C212.437 609 216.837 603.4 216.837 592.2V554H236.337V592.8C236.337 603.2 233.437 611.233 227.637 616.9C221.903 622.567 213.837 625.4 203.437 625.4Z"
                fill="#4DBC15"
              />
              <path
                d="M274.35 625.4C268.683 625.4 263.183 624.7 257.85 623.3C252.517 621.9 248.183 620.033 244.85 617.7L251.35 603.1C254.483 605.167 258.117 606.833 262.25 608.1C266.383 609.367 270.45 610 274.45 610C282.05 610 285.85 608.1 285.85 604.3C285.85 602.3 284.75 600.833 282.55 599.9C280.417 598.9 276.95 597.867 272.15 596.8C266.883 595.667 262.483 594.467 258.95 593.2C255.417 591.867 252.383 589.767 249.85 586.9C247.317 584.033 246.05 580.167 246.05 575.3C246.05 571.033 247.217 567.2 249.55 563.8C251.883 560.333 255.35 557.6 259.95 555.6C264.617 553.6 270.317 552.6 277.05 552.6C281.65 552.6 286.183 553.133 290.65 554.2C295.117 555.2 299.05 556.7 302.45 558.7L296.35 573.4C289.683 569.8 283.217 568 276.95 568C273.017 568 270.15 568.6 268.35 569.8C266.55 570.933 265.65 572.433 265.65 574.3C265.65 576.167 266.717 577.567 268.85 578.5C270.983 579.433 274.417 580.4 279.15 581.4C284.483 582.533 288.883 583.767 292.35 585.1C295.883 586.367 298.917 588.433 301.45 591.3C304.05 594.1 305.35 597.933 305.35 602.8C305.35 607 304.183 610.8 301.85 614.2C299.517 617.6 296.017 620.333 291.35 622.4C286.683 624.4 281.017 625.4 274.35 625.4Z"
                fill="#4DBC15"
              />
              <path
                d="M370.696 608.7V624H314.496V554H369.396V569.3H334.096V581.1H365.196V595.9H334.096V608.7H370.696Z"
                fill="#4DBC15"
              />
              <path d="M381.684 554H401.484V624H381.684V554Z" fill="#4DBC15" />
              <path
                d="M415.57 554H448.67C456.337 554 463.137 555.433 469.07 558.3C475.004 561.167 479.604 565.233 482.87 570.5C486.137 575.767 487.77 581.933 487.77 589C487.77 596.067 486.137 602.233 482.87 607.5C479.604 612.767 475.004 616.833 469.07 619.7C463.137 622.567 456.337 624 448.67 624H415.57V554ZM447.87 608.2C453.87 608.2 458.67 606.5 462.27 603.1C465.937 599.7 467.77 595 467.77 589C467.77 583 465.937 578.3 462.27 574.9C458.67 571.5 453.87 569.8 447.87 569.8H435.37V608.2H447.87Z"
                fill="#4DBC15"
              />
              <path
                d="M554.388 608.7V624H498.188V554H553.088V569.3H517.787V581.1H548.888V595.9H517.787V608.7H554.388Z"
                fill="#4DBC15"
              />
              <path
                d="M612.275 610.4H582.675L577.175 624H556.975L587.875 554H607.375L638.375 624H617.775L612.275 610.4ZM606.475 595.8L597.475 573.4L588.475 595.8H606.475Z"
                fill="#4DBC15"
              />
              <path
                d="M22.4531 706.468C18.3581 706.468 14.6531 705.571 11.3381 703.777C8.06211 701.944 5.48811 699.448 3.61611 696.289C1.74411 693.091 0.808105 689.503 0.808105 685.525C0.808105 681.547 1.74411 677.979 3.61611 674.82C5.48811 671.622 8.08161 669.126 11.3966 667.332C14.7116 665.499 18.4166 664.582 22.5116 664.582C25.7096 664.582 28.6346 665.128 31.2866 666.22C33.9386 667.273 36.2006 668.853 38.0726 670.959L34.2701 674.644C31.1891 671.407 27.3476 669.789 22.7456 669.789C19.7036 669.789 16.9541 670.471 14.4971 671.836C12.0401 673.201 10.1096 675.093 8.70561 677.511C7.34061 679.89 6.65811 682.561 6.65811 685.525C6.65811 688.489 7.34061 691.18 8.70561 693.598C10.1096 695.977 12.0401 697.849 14.4971 699.214C16.9541 700.579 19.7036 701.262 22.7456 701.262C27.3086 701.262 31.1501 699.624 34.2701 696.348L38.0726 700.033C36.2006 702.139 33.9191 703.738 31.2281 704.83C28.5761 705.922 25.6511 706.468 22.4531 706.468Z"
                fill="black"
              />
              <path
                d="M73.5268 674.995V706H68.2033V701.32C67.0723 702.919 65.5708 704.167 63.6988 705.064C61.8658 705.922 59.8573 706.351 57.6733 706.351C53.5393 706.351 50.2828 705.22 47.9038 702.958C45.5248 700.657 44.3353 697.284 44.3353 692.838V674.995H49.9513V692.194C49.9513 695.197 50.6728 697.479 52.1158 699.039C53.5588 700.56 55.6258 701.32 58.3168 701.32C61.2808 701.32 63.6208 700.423 65.3368 698.629C67.0528 696.835 67.9108 694.3 67.9108 691.024V674.995H73.5268Z"
                fill="black"
              />
              <path
                d="M93.1101 706.351C90.5751 706.351 88.1376 706.02 85.7976 705.357C83.4576 704.655 81.6246 703.777 80.2986 702.724L82.6386 698.278C84.0036 699.253 85.6611 700.033 87.6111 700.618C89.5611 701.203 91.5306 701.496 93.5196 701.496C98.4336 701.496 100.891 700.092 100.891 697.284C100.891 696.348 100.559 695.607 99.8961 695.061C99.2331 694.515 98.3946 694.125 97.3806 693.891C96.4056 693.618 95.0016 693.325 93.1686 693.013C90.6726 692.623 88.6251 692.175 87.0261 691.668C85.4661 691.161 84.1206 690.303 82.9896 689.094C81.8586 687.885 81.2931 686.188 81.2931 684.004C81.2931 681.196 82.4631 678.954 84.8031 677.277C87.1431 675.561 90.2826 674.703 94.2216 674.703C96.2886 674.703 98.3556 674.956 100.423 675.463C102.49 675.97 104.186 676.653 105.512 677.511L103.114 681.957C100.579 680.319 97.5951 679.5 94.1631 679.5C91.7841 679.5 89.9706 679.89 88.7226 680.67C87.4746 681.45 86.8506 682.483 86.8506 683.77C86.8506 684.784 87.2016 685.584 87.9036 686.169C88.6056 686.754 89.4636 687.183 90.4776 687.456C91.5306 687.729 92.9931 688.041 94.8651 688.392C97.3611 688.821 99.3696 689.289 100.891 689.796C102.451 690.264 103.777 691.083 104.869 692.253C105.961 693.423 106.507 695.061 106.507 697.167C106.507 699.975 105.298 702.217 102.88 703.894C100.501 705.532 97.2441 706.351 93.1101 706.351Z"
                fill="black"
              />
              <path
                d="M130.958 704.187C130.139 704.889 129.125 705.435 127.916 705.825C126.707 706.176 125.459 706.351 124.172 706.351C121.052 706.351 118.634 705.513 116.918 703.836C115.202 702.159 114.344 699.76 114.344 696.64V679.617H109.079V674.995H114.344V668.209H119.96V674.995H128.852V679.617H119.96V696.406C119.96 698.083 120.37 699.37 121.189 700.267C122.047 701.164 123.256 701.613 124.816 701.613C126.532 701.613 127.994 701.125 129.203 700.15L130.958 704.187Z"
                fill="black"
              />
              <path
                d="M149.999 706.351C146.918 706.351 144.149 705.669 141.692 704.304C139.235 702.939 137.305 701.067 135.901 698.688C134.536 696.27 133.853 693.54 133.853 690.498C133.853 687.456 134.536 684.745 135.901 682.366C137.305 679.948 139.235 678.076 141.692 676.75C144.149 675.385 146.918 674.703 149.999 674.703C153.08 674.703 155.83 675.385 158.248 676.75C160.705 678.076 162.616 679.948 163.981 682.366C165.385 684.745 166.087 687.456 166.087 690.498C166.087 693.54 165.385 696.27 163.981 698.688C162.616 701.067 160.705 702.939 158.248 704.304C155.83 705.669 153.08 706.351 149.999 706.351ZM149.999 701.437C151.988 701.437 153.763 700.989 155.323 700.092C156.922 699.156 158.17 697.869 159.067 696.231C159.964 694.554 160.412 692.643 160.412 690.498C160.412 688.353 159.964 686.461 159.067 684.823C158.17 683.146 156.922 681.859 155.323 680.962C153.763 680.065 151.988 679.617 149.999 679.617C148.01 679.617 146.216 680.065 144.617 680.962C143.057 681.859 141.809 683.146 140.873 684.823C139.976 686.461 139.528 688.353 139.528 690.498C139.528 692.643 139.976 694.554 140.873 696.231C141.809 697.869 143.057 699.156 144.617 700.092C146.216 700.989 148.01 701.437 149.999 701.437Z"
                fill="black"
              />
              <path
                d="M212.639 674.703C216.539 674.703 219.62 675.834 221.882 678.096C224.183 680.358 225.333 683.712 225.333 688.158V706H219.717V688.801C219.717 685.798 219.015 683.536 217.611 682.015C216.246 680.494 214.277 679.734 211.703 679.734C208.856 679.734 206.594 680.631 204.917 682.425C203.24 684.18 202.401 686.715 202.401 690.03V706H196.785V688.801C196.785 685.798 196.083 683.536 194.679 682.015C193.314 680.494 191.345 679.734 188.771 679.734C185.924 679.734 183.662 680.631 181.985 682.425C180.308 684.18 179.469 686.715 179.469 690.03V706H173.853V674.995H179.235V679.617C180.366 678.018 181.848 676.809 183.681 675.99C185.514 675.132 187.601 674.703 189.941 674.703C192.359 674.703 194.504 675.19 196.376 676.165C198.248 677.14 199.691 678.564 200.705 680.436C201.875 678.642 203.493 677.238 205.56 676.224C207.666 675.21 210.026 674.703 212.639 674.703Z"
                fill="black"
              />
              <path
                d="M287.413 665.05V706H281.563V687.748H258.046V706H252.196V665.05H258.046V682.659H281.563V665.05H287.413Z"
                fill="black"
              />
              <path
                d="M312.131 706.351C309.05 706.351 306.281 705.669 303.824 704.304C301.367 702.939 299.436 701.067 298.032 698.688C296.667 696.27 295.985 693.54 295.985 690.498C295.985 687.456 296.667 684.745 298.032 682.366C299.436 679.948 301.367 678.076 303.824 676.75C306.281 675.385 309.05 674.703 312.131 674.703C315.212 674.703 317.961 675.385 320.379 676.75C322.836 678.076 324.747 679.948 326.112 682.366C327.516 684.745 328.218 687.456 328.218 690.498C328.218 693.54 327.516 696.27 326.112 698.688C324.747 701.067 322.836 702.939 320.379 704.304C317.961 705.669 315.212 706.351 312.131 706.351ZM312.131 701.437C314.12 701.437 315.894 700.989 317.454 700.092C319.053 699.156 320.301 697.869 321.198 696.231C322.095 694.554 322.544 692.643 322.544 690.498C322.544 688.353 322.095 686.461 321.198 684.823C320.301 683.146 319.053 681.859 317.454 680.962C315.894 680.065 314.12 679.617 312.131 679.617C310.142 679.617 308.348 680.065 306.749 680.962C305.189 681.859 303.941 683.146 303.005 684.823C302.108 686.461 301.659 688.353 301.659 690.498C301.659 692.643 302.108 694.554 303.005 696.231C303.941 697.869 305.189 699.156 306.749 700.092C308.348 700.989 310.142 701.437 312.131 701.437Z"
                fill="black"
              />
              <path
                d="M374.771 674.703C378.671 674.703 381.752 675.834 384.014 678.096C386.315 680.358 387.465 683.712 387.465 688.158V706H381.849V688.801C381.849 685.798 381.147 683.536 379.743 682.015C378.378 680.494 376.409 679.734 373.835 679.734C370.988 679.734 368.726 680.631 367.049 682.425C365.372 684.18 364.533 686.715 364.533 690.03V706H358.917V688.801C358.917 685.798 358.215 683.536 356.811 682.015C355.446 680.494 353.477 679.734 350.903 679.734C348.056 679.734 345.794 680.631 344.117 682.425C342.44 684.18 341.601 686.715 341.601 690.03V706H335.985V674.995H341.367V679.617C342.498 678.018 343.98 676.809 345.813 675.99C347.646 675.132 349.733 674.703 352.073 674.703C354.491 674.703 356.636 675.19 358.508 676.165C360.38 677.14 361.823 678.564 362.837 680.436C364.007 678.642 365.625 677.238 367.692 676.224C369.798 675.21 372.158 674.703 374.771 674.703Z"
                fill="black"
              />
              <path
                d="M425.992 690.673C425.992 691.102 425.953 691.668 425.875 692.37H400.72C401.071 695.1 402.26 697.303 404.288 698.98C406.355 700.618 408.91 701.437 411.952 701.437C415.657 701.437 418.64 700.189 420.902 697.693L424.003 701.32C422.599 702.958 420.844 704.206 418.738 705.064C416.671 705.922 414.35 706.351 411.776 706.351C408.5 706.351 405.595 705.688 403.06 704.362C400.525 702.997 398.555 701.106 397.151 698.688C395.786 696.27 395.104 693.54 395.104 690.498C395.104 687.495 395.767 684.784 397.093 682.366C398.458 679.948 400.31 678.076 402.65 676.75C405.029 675.385 407.701 674.703 410.665 674.703C413.629 674.703 416.261 675.385 418.562 676.75C420.902 678.076 422.716 679.948 424.003 682.366C425.329 684.784 425.992 687.553 425.992 690.673ZM410.665 679.441C407.974 679.441 405.712 680.26 403.879 681.898C402.085 683.536 401.032 685.681 400.72 688.333H420.61C420.298 685.72 419.225 683.595 417.392 681.957C415.598 680.28 413.356 679.441 410.665 679.441Z"
                fill="black"
              />
              <path
                d="M477.873 684.765C480.252 685.428 482.124 686.637 483.489 688.392C484.854 690.108 485.536 692.292 485.536 694.944C485.536 698.493 484.191 701.223 481.5 703.134C478.848 705.045 474.967 706 469.858 706H450.319V665.05H468.688C473.368 665.05 476.976 665.986 479.511 667.858C482.085 669.73 483.372 672.324 483.372 675.639C483.372 677.784 482.865 679.636 481.851 681.196C480.876 682.756 479.55 683.946 477.873 684.765ZM456.169 669.789V682.893H468.162C471.165 682.893 473.466 682.347 475.065 681.255C476.703 680.124 477.522 678.486 477.522 676.341C477.522 674.196 476.703 672.577 475.065 671.485C473.466 670.354 471.165 669.789 468.162 669.789H456.169ZM469.624 701.262C472.939 701.262 475.435 700.716 477.112 699.624C478.789 698.532 479.628 696.816 479.628 694.476C479.628 689.913 476.293 687.631 469.624 687.631H456.169V701.262H469.624Z"
                fill="black"
              />
              <path
                d="M522.674 674.995V706H517.351V701.32C516.22 702.919 514.718 704.167 512.846 705.064C511.013 705.922 509.005 706.351 506.821 706.351C502.687 706.351 499.43 705.22 497.051 702.958C494.672 700.657 493.483 697.284 493.483 692.838V674.995H499.099V692.194C499.099 695.197 499.82 697.479 501.263 699.039C502.706 700.56 504.773 701.32 507.464 701.32C510.428 701.32 512.768 700.423 514.484 698.629C516.2 696.835 517.058 694.3 517.058 691.024V674.995H522.674Z"
                fill="black"
              />
              <path
                d="M533.366 674.995H538.982V706H533.366V674.995ZM536.174 669.028C535.082 669.028 534.165 668.677 533.424 667.975C532.722 667.273 532.371 666.415 532.371 665.401C532.371 664.387 532.722 663.529 533.424 662.827C534.165 662.086 535.082 661.716 536.174 661.716C537.266 661.716 538.163 662.067 538.865 662.769C539.606 663.432 539.976 664.27 539.976 665.284C539.976 666.337 539.606 667.234 538.865 667.975C538.163 668.677 537.266 669.028 536.174 669.028Z"
                fill="black"
              />
              <path
                d="M549.704 662.593H555.32V706H549.704V662.593Z"
                fill="black"
              />
              <path
                d="M595.293 662.593V706H589.911V701.086C588.663 702.802 587.084 704.109 585.173 705.006C583.262 705.903 581.156 706.351 578.855 706.351C575.852 706.351 573.161 705.688 570.782 704.362C568.403 703.036 566.531 701.184 565.166 698.805C563.84 696.387 563.177 693.618 563.177 690.498C563.177 687.378 563.84 684.628 565.166 682.249C566.531 679.87 568.403 678.018 570.782 676.692C573.161 675.366 575.852 674.703 578.855 674.703C581.078 674.703 583.125 675.132 584.997 675.99C586.869 676.809 588.429 678.037 589.677 679.675V662.593H595.293ZM579.323 701.437C581.273 701.437 583.047 700.989 584.646 700.092C586.245 699.156 587.493 697.869 588.39 696.231C589.287 694.554 589.736 692.643 589.736 690.498C589.736 688.353 589.287 686.461 588.39 684.823C587.493 683.146 586.245 681.859 584.646 680.962C583.047 680.065 581.273 679.617 579.323 679.617C577.334 679.617 575.54 680.065 573.941 680.962C572.381 681.859 571.133 683.146 570.197 684.823C569.3 686.461 568.851 688.353 568.851 690.498C568.851 692.643 569.3 694.554 570.197 696.231C571.133 697.869 572.381 699.156 573.941 700.092C575.54 700.989 577.334 701.437 579.323 701.437Z"
                fill="black"
              />
              <path
                d="M633.941 690.673C633.941 691.102 633.902 691.668 633.824 692.37H608.669C609.02 695.1 610.209 697.303 612.237 698.98C614.304 700.618 616.859 701.437 619.901 701.437C623.606 701.437 626.589 700.189 628.851 697.693L631.952 701.32C630.548 702.958 628.793 704.206 626.687 705.064C624.62 705.922 622.299 706.351 619.725 706.351C616.449 706.351 613.544 705.688 611.009 704.362C608.474 702.997 606.504 701.106 605.1 698.688C603.735 696.27 603.053 693.54 603.053 690.498C603.053 687.495 603.716 684.784 605.042 682.366C606.407 679.948 608.259 678.076 610.599 676.75C612.978 675.385 615.65 674.703 618.614 674.703C621.578 674.703 624.21 675.385 626.511 676.75C628.851 678.076 630.665 679.948 631.952 682.366C633.278 684.784 633.941 687.553 633.941 690.673ZM618.614 679.441C615.923 679.441 613.661 680.26 611.828 681.898C610.034 683.536 608.981 685.681 608.669 688.333H628.559C628.247 685.72 627.174 683.595 625.341 681.957C623.547 680.28 621.305 679.441 618.614 679.441Z"
                fill="black"
              />
              <path
                d="M647.121 680.202C648.096 678.408 649.539 677.043 651.45 676.107C653.361 675.171 655.682 674.703 658.412 674.703V680.143C658.1 680.104 657.671 680.085 657.125 680.085C654.083 680.085 651.684 681.001 649.929 682.834C648.213 684.628 647.355 687.202 647.355 690.556V706H641.739V674.995H647.121V680.202Z"
                fill="black"
              />
            </>
          ) : null}
        </svg>
      </LogoContainer>
    )
  }
}
