import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from './logo-svg'
import Button from './button'

const HeaderOuterContainer = styled.div``
const HeaderFluidContainer = styled.div`
  padding: 1em 15px !important;
  z-index: 100;
  position: relative;
  background: #41a928;
  @media (min-width: 992px) {
    background: #f2f2f2 !important;
  }
  &:before {
    content: '';
    left: 0;
    background: #41a928;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
    left: 0;
    width: 33%;
    height: 100%;
    position: absolute;
    top: 0;
  }
`
const HeaderContainer = styled.div``
const NavigationOuterContainer = styled.div``
const StickyContainer = styled.div`
  position: sticky;
  z-index: 100;
  top: 0;
`
const LogoSVG = styled.div`
  width: 170px;
  svg path {
    fill: white !important;
  }
  z-index: 100;
`
const PhoneNumberHeader = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2em;
  color: #333 !important;
  margin-right: 10px;
  @media (max-width: 992px) {
    font-size: 1.2em;
  }
`

const HeaderLogo = (siteTitle) => (
  <Link
    to="/"
    className="col-6 col-lg-4 d-flex align-items-center"
    style={{ lineHeight: '0', zIndex: '100', display: 'block' }}
  >
    <LogoSVG alt={siteTitle.siteTitle + ' Logo'}>
      <Logo color="white-desktop" />
    </LogoSVG>
  </Link>
)
const HeaderTagline = (siteDescription) => (
  <p className="mb-0 d-none d-xl-flex col-xl-4">
    {siteDescription.siteDescription}
  </p>
)
const HeaderPhoneFreeEstimateButton = (phone) => {
  return (
    <div className="headeroneph">
      <span className="givecalltoday">
        Give Us A Call Today!
        <br />
      </span>
      <PhoneNumberHeader href={'tel:' + phone.phone}>
        {phone.phone}
      </PhoneNumberHeader>
      <Button to={'/contact/'} color={'primary cta-button'}>
        Contact Us
      </Button>
    </div>
  )
}

class Headernew extends React.Component {
  render() {
    let { siteTitle, companyInfo } = this.props
    return (
      <HeaderOuterContainer>
        <HeaderFluidContainer>
          <HeaderContainer className="container d-flex justify-content-between align-items-center">
            <HeaderLogo siteTitle={siteTitle} />
            <HeaderTagline siteDescription={this.props.siteDescription} />
            <HeaderPhoneFreeEstimateButton
              phone={companyInfo.phone}
              className="d-none d-lg-flex align-items-center col-lg-8 col-xl-4 justify-content-end"
            />
          </HeaderContainer>
        </HeaderFluidContainer>

        <NavigationOuterContainer>
          Navigation goes here
        </NavigationOuterContainer>
      </HeaderOuterContainer>
    )
  }
}

export default Headernew
