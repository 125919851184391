import React, { useRef, useState, useEffect } from 'react'
import { Link, useStaticQuery } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Check, X } from 'react-feather'
import Logo from '../components/logo-svg'
import { useHiddenmsClassCity } from '../hooks/useHiddenmsClass'
import { useMatchHiddenClass } from '../hooks/useMatchHiddenClass'
import { getLocPhysicalAndCity as valueTrackParams } from '../helpers/adRefTracking'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'

import YelpIcon from '../assets/icons/yelp'
import FacebookIcon from '../assets/icons/facebook'
import InstagramIcon from '../assets/icons/instagram'
import GoogleIcon from '../assets/icons/google'
import YoutubeIcon from '../assets/icons/youtube'
import GuildqualityIcon from '../assets/icons/guildquality'
import FooterAreasServedList from './footer-areas-served'

const LegalLinks = styled.div`
  margin-top: 5px;
  a {
    color: #989898;

    margin-right: 10px;
    font-size: 0.9em;
    &:hover {
      color: #fff;
    }
  }
`
const AreasServedUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 50%;
  }
`
const LogoSVG = styled.div`
  max-width: 170px;
`

const FooterOuterContainer = styled.div`
  padding: 4em 0;
  font-size: 14px;
  color: #222;
  font-weight: 500;
  ul {
    list-style: none;
    margin: 0;
    li {
      margin-bottom: 15px;
      a {
        color: #222;
      }
    }
  }
  a {
    color: #222;
    &:hover {
      color: #41a928;
    }
  }
`
const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid #ececec;
  padding: 2em 0;
  margin-top: 2em;

  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
`
const CopyrightText = styled.div`
  color: #888;
  margin: 0;
  padding: 0.5em;
`

const SidebarP = styled.p`
  color: #777;
  word-wrap: break-word;
`
const FooterLinksMenu = styled.ul`
  color: #000;
`
const CopyrightSlogan = styled.span`
  font-style: italic;
  display: block;
`

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    list-style-type: none;
    padding: 0;
    padding-left: 20px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 4px;
      margin-left: -24px;
      padding: 4px;
      position: absolute;
      top: 0;
      @media (max-width: 767px) {
        top: -2px !important;
      }
    }
  }
`

const SocialMediaIcon = styled.div`
  path {
    fill: #777;
  }
  padding: 0.5em 0;
  a {
    svg {
      height: 24px;
    }
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  line-height: 0;
  @media (min-width: 767px) {
    padding-right: 3em;{isHomePage ? (
      <>
        <br />
        <span>
          {' '}
          Web Design &amp; Marketing by{' '}
          <a
            target="_blank"
            href="https://www.webperfex.com/?utm_source=houseidea&utm_medium=referral"
            style={{ color: 'rgb(136, 136, 136)' }}
          >
            WebPerfex
          </a>
        </span>
      </>
    ) : null}
  }
`

const Footer = ({ isHomePage = false }) => {
  const footerRefs = useHiddenmsClassCity({
    handler: (ref) => {
      const className = ref.current.classList
      ref.current.className = className + ' hiddenms'
    },
  })
  const sidingCitiesLinksRef = useRef()
  const sidingCitiesLinks = useMatchHiddenClass({
    matchFunction: () => !!valueTrackParams().campaigns,
  })
  sidingCitiesLinks.push(sidingCitiesLinksRef)
  const addressRef = useRef()
  footerRefs.push(addressRef)

  const WhyUs = (props) => {
    return (
      <>
        <h4 className=" text-footer-dark text-lg lg:text-xl mb-8">
          Why Choose HouseIdea?
        </h4>
        <WhyUsUnorderedList>
          {props.siteMetadata.whyUs.map((item, index) => (
            <li key={index}>
              <Check size={20} className="text-color-primary" />
              {item}
            </li>
          ))}
        </WhyUsUnorderedList>
      </>
    )
  }

  const AreasServed = (props) => {
    let { serviceArea } = props

    return (
      <>
        <h4 className=" text-footer-dark text-lg lg:text-xl mb-8">
          Where We Build Homes
        </h4>
        <AreasServedUl>
          {serviceArea.edges.map((item, index) => {
            return (
              <li key={index}>
                {
                  <Link
                    to={
                      item && item.node && item.node.customFooterLinkUrl
                        ? item.node.customFooterLinkUrl
                        : `/custom-home-builder-${item.node.slug}/`
                    }
                    title={
                      item.node.customFooterLinkAltTag
                        ? item.node.customFooterLinkAltTag
                        : item.node.location + ' Custom Homes'
                    }
                  >
                    {item && item.node && item.node.customFooterLinkTitle
                      ? item && item.node && item.node.customFooterLinkTitle
                      : item && item.node && item.node.location + ', CA'}
                  </Link>
                }
              </li>
            )
          })}
        </AreasServedUl>
        <Link to={'/cities/'}>
          <i>View Complete Service Area</i>
        </Link>
      </>
    )
  }

  const FooterColumms = (footerData) => {
    const footerColumns = footerData.data.map((footer, index) => (
      <FooterColumm
        key={index}
        footerInfo={footer}
        columnsCount={footerData.data.length}
        siteData={footerData.siteData.site}
        serviceArea={footerData.siteData.allContentfulServiceArea}
      />
    ))
    return footerColumns
  }

  const FooterMenuLink = ({ menuItem, index, remodelingRefs }) => {
    const menuItemRef = useRef(null)
    if (menuItem.title.includes('Remodeling')) {
      remodelingRefs.push(menuItemRef)
    }
    return (
      <li key={index}>
        <Link
          to={menuItem.url ? menuItem.url : '#'}
          title={menuItem.title}
          activeClassName={'active'}
          ref={menuItemRef}
        >
          {menuItem.title}
        </Link>
      </li>
    )
  }

  const FooterColumm = (footerInfo) => {
    const remodelingRefs = useHiddenmsClassCity()
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes('{{CompanyInfo}}')
    ) {
      return (
        <div
          className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
          style={{
            order:
              footerInfo.footerInfo &&
              footerInfo.footerInfo.node &&
              footerInfo.footerInfo.node.order,
          }}
        >
          <CompanyInfoColumn siteMetadata={footerInfo.siteData.siteMetadata} />
        </div>
      )
    }
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes('{{WhyUs}}')
    ) {
      return (
        <div
          className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
          style={{
            order:
              footerInfo.footerInfo &&
              footerInfo.footerInfo.node &&
              footerInfo.footerInfo.node.order,
          }}
        >
          <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
        </div>
      )
    }
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes('{{AreasServed}}')
    ) {
      return (
        <div
          className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
          style={{
            order:
              footerInfo.footerInfo &&
              footerInfo.footerInfo.node &&
              footerInfo.footerInfo.node.order,
          }}
        >
          <AreasServed serviceArea={footerInfo.serviceArea} />
        </div>
      )
    }

    if (footerInfo.footerInfo.node.menuLinks) {
      const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map(
        (menuItem, index) => (
          <FooterMenuLink
            key={index}
            menuItem={menuItem}
            index={index}
            remodelingRefs={remodelingRefs}
          />
        )
      )
      return (
        <div
          className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
          style={{
            order:
              footerInfo.footerInfo &&
              footerInfo.footerInfo.node &&
              footerInfo.footerInfo.node.order,
          }}
        >
          <h4 className=" text-footer-dark text-lg lg:text-xl mb-8">
            {footerInfo.footerInfo.node.title}
          </h4>
          <FooterLinksMenu>{footerLinksMapped}</FooterLinksMenu>
        </div>
      )
    }

    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <h4 className="text-footer-dark">{footerInfo.footerInfo.node.title}</h4>
        {footerInfo.footerInfo.node &&
        footerInfo.footerInfo.node.html &&
        footerInfo.footerInfo.node.html.html ? (
          <div
            dangerouslySetInnerHTML={{
              __html: footerInfo.footerInfo.node.html.html,
            }}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  const CompanyInfoColumn = (props) => {
    let { siteMetadata } = props

    return (
      <>
        <div className="row m-0">
          <div className="col-12 col-md-12 mb-4">
            <LogoSVG alt={'HouseIdea Logo'}>
              <Logo hideLogoText={true} />
            </LogoSVG>
          </div>

          <div className="col-6 col-md-12 mb-4 px-1">
            <SocialMediaIcons>
              {siteMetadata.social.facebook ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.facebook}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <FacebookIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.google ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.google}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <GoogleIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.youtube ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.youtube}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <YoutubeIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.instagram ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.instagram}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <InstagramIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.guildquality ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.guildquality}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <GuildqualityIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}

              {siteMetadata.social.yelp ? (
                <SocialMediaIcon>
                  <a
                    href={siteMetadata.social.yelp}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <YelpIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ''
              )}
            </SocialMediaIcons>
          </div>

          <CopyrightText className="font-normal">
            &copy; {new Date().getFullYear()} {data.site.siteMetadata.title},
            Inc. All Rights Reserved. Lic #: {siteMetadata.companyInfo.license}
            <br />
            <span
              itemScope
              className="rating-desc"
              itemType="https://schema.org/Product"
            >
              <span itemProp="name">{data.site.siteMetadata.title}</span>{' '}
              <span
                itemScope
                itemProp="aggregateRating"
                itemType="https://schema.org/AggregateRating"
              >
                rated <span itemProp="ratingValue">{REVIEW_AVG}</span> / 5 based
                on <span itemProp="reviewCount">{TOTAL_REVIEW_COUNT}</span>{' '}
                reviews.
              </span>
            </span>
            {isHomePage ? (
              <>
                <br />
                <span style={{ fontSize: '13px' }}>
                  {' '}
                  Web Design &amp; Marketing by{' '}
                  <a
                    target="_blank"
                    href="https://www.webperfex.com/?utm_source=houseidea&utm_medium=referral"
                    style={{ color: 'rgb(136, 136, 136)', fontSize: '13px' }}
                  >
                    WebPerfex
                  </a>
                </span>
              </>
            ) : null}
            <br />
            <LegalLinks>
              <a
                href="/privacy-policy/"
                title="Learn about our commitment to privacy"
                rel="nofollow"
              >
                Privacy Policy
              </a>
              <a href="/contact/" title="Contact Us" rel="nofollow">
                Contact Us
              </a>
            </LegalLinks>
          </CopyrightText>

          <div className="col-12 col-md-12 hidden">
            <h4 className="text-footer-dark mb-1">
              {siteMetadata.companyInfo.phone}
            </h4>

            <span className="text-base text-gray-500 block font-normal">
              <span ref={addressRef}>Citrus Heights, CA 95610</span>
            </span>
          </div>
        </div>
      </>
    )
  }

  const areasServedPopupOpenRef = useRef()
  const [areasServedPopupOpen, setAreasServedPopupOpen] = useState(false)
  const toggleReviewsModal = (state) => {
    setAreasServedPopupOpen(state)
  }

  useEffect(() => {
    if (areasServedPopupOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [areasServedPopupOpen])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        toggleReviewsModal(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  useOnClickOutside(areasServedPopupOpenRef, () => toggleReviewsModal(false))

  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          whyUs
          companyInfo {
            phone
            email
            addressLine1
            addressLine2
            city
            state
            zip
            license
          }
          social {
            yelp
            facebook
            instagram
            youtube
            google
            guildquality
          }
        }
      }
      allContentfulFooterFooterColumn(filter: { campaign: { eq: null } }) {
        edges {
          node {
            id
            title
            order
            menuLinks {
              id
              name
              title
              url
              openInNewWindow
              submenu {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                }
              }
            }
            html {
              html
            }
          }
        }
      }
      footerMenuOptions: allContentfulFooterFooterColumn(
        filter: { campaign: { ne: null } }
      ) {
        edges {
          node {
            id
            title
            order
            campaign
            menuLinks {
              id
              name
              title
              url
              openInNewWindow
              submenu {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                }
              }
            }
            html {
              html
            }
          }
        }
      }
      defaultAllContentfulFooterFooterColumn: allContentfulFooterFooterColumn(
        filter: { campaign: { in: ["default"] } }
      ) {
        edges {
          node {
            id
            title
            order
            campaign
            menuLinks {
              id
              name
              title
              url
              openInNewWindow
              submenu {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                }
              }
            }
            html {
              html
            }
          }
        }
      }
      allContentfulServiceArea(
        filter: { featuredCity: { eq: true } }
        sort: { fields: location, order: ASC }
      ) {
        edges {
          node {
            location
            id
            slug
            customFooterLinkAltTag
            customFooterLinkUrl
            customFooterLinkTitle
          }
        }
      }
      allContentfulServiceAreaAll: allContentfulServiceArea(
        sort: { fields: location, order: ASC }
      ) {
        edges {
          node {
            location
            id
            slug
            customFooterLinkAltTag
            customFooterLinkUrl
            customFooterLinkTitle
          }
        }
      }
      kitchenCities: allContentfulServiceArea(
        sort: { fields: location, order: ASC }
        filter: {
          marketSegment: { nin: "bayarea" }
          includeInFooter: { eq: true }
        }
      ) {
        edges {
          node {
            location
            slug
            marketSegment
            includeInFooter
          }
        }
      }
    }
  `)

  //assuming length of array equals columns intended
  let footerMenu = [
    ...data.allContentfulFooterFooterColumn.edges,
    ...data.defaultAllContentfulFooterFooterColumn.edges,
  ]

  const [defaultFooterMenu, setFooterMenu] = useState(footerMenu)

  useEffect(() => {
    const { campaigns = 'default' } = valueTrackParams()
    if (campaigns != 'default') {
      const matchingCampaignItems = data.footerMenuOptions.edges.filter(
        ({ node: { campaign } }) =>
          campaign.length > 0 ? campaign[0] == campaigns : false
      )

      if (matchingCampaignItems.length > 0) {
        setFooterMenu(
          [...data.allContentfulFooterFooterColumn.edges].concat(
            matchingCampaignItems
          )
        )
      }
    }
  }, [])

  let { siteMetadata } = data.site

  return (
    <FooterOuterContainer className="container-fluid bg-white foottablet">
      <div
        className={`modal-bg-overlay ${areasServedPopupOpen ? 'active' : null}`}
      />
      <div
        className={` overflow-y-scroll  h-full standard-modal ${
          areasServedPopupOpen ? 'active' : null
        }`}
        ref={areasServedPopupOpenRef}
      >
        <div className="flex items-center justify-between px-8 py-6">
          <div className="font-semibold text-black text-2xl">Areas Served</div>
          <button
            type="button"
            onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
            className="border-0 bg-white hover:gvd-main-bg-darker cursor-pointer rounded-full w-10 h-10 flex text-black items-center justify-center"
          >
            {' '}
            <X size={28} />
          </button>
        </div>
        <div className="px-8">
          <FooterAreasServedList />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <FooterColumms data={defaultFooterMenu} siteData={data} />
        </div>
      </div>
      <div className="container">
        <CopyrightContainer ref={sidingCitiesLinksRef}>
          <CopyrightText className="footercities font-normal">
            HouseIdea serves the following cities:&nbsp;
            <Link to={'/'} title={'Custom Home Builder Sacramento CA'}>
              Sacramento
            </Link>
            {', '}
            {data.allContentfulServiceAreaAll.edges.map(
              ({ node: { id, location, slug } }, i) => (
                <>
                  <Link
                    key={id}
                    to={`/custom-home-builder-${slug}/`}
                    title={`${location} Custom Home Builder`}
                  >
                    {location}
                  </Link>
                  {', '}
                </>
              )
            )}
            {data.allContentfulServiceAreaAll.edges.map(
              ({ node: { id, location, slug } }, i) => (
                <>
                  <Link
                    key={id}
                    to={`/home-additions-${slug}/`}
                    title={`${location} Home Additions`}
                  >
                    {location}
                  </Link>
                  {', '}
                </>
              )
            )}
            {data.allContentfulServiceAreaAll.edges.map(
              ({ node: { id, location, slug } }, i) => (
                <>
                  <Link
                    key={id}
                    to={`/new-construction-${slug}/`}
                    title={`${location} New Construction Contractor`}
                  >
                    {location}
                  </Link>
                  {', '}
                </>
              )
            )}
          </CopyrightText>
        </CopyrightContainer>
      </div>
    </FooterOuterContainer>
  )
}

export default Footer
