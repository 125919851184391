import React from 'react'

export const ColumnWrapper = ({ children, columns }) =>
  columns ? (
    <li className={`col-6 submenuline`}>
      <ul className="twocolmenu">{children}</ul>
    </li>
  ) : (
    children
  )
