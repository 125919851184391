import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import ContactForm from '../estimate-form'

import {
  MenuLi,
  SubMenu,
  SubSection,
  SubSectionWrapper,
  SectionTitle,
  LogoSVG,
  PhoneNumberHeader,
  HeaderOuterContainer,
  NavOuterContainer,
  NavigationMobileUL,
  MobileSecondaryCTA,
  StickyContainer,
  LogoContainer,
  MenuMobile,
  MobileIconBurger,
  CTASectionWrapper,
  SiteTagline,
} from './components'
import { ColumnWrapper } from './ColumnWrapper'
import Logo from '../logo-svg'
import Button from '../button'
import { X } from 'react-feather'

import { getLocPhysicalAndCity as getValueTrackingParams } from '../../helpers/adRefTracking'

import { ServingCitiesText } from '../ServingCitiesText'
import { personalizedServingText } from '../../helpers/personalizedServingText'

let windowWidth = 0
if (typeof window !== `undefined`) {
  windowWidth = window.innerWidth
}

const itemsPerColumn = 7

const MainNavigation = ({
  mainNavigation,
  refCallback,
  level = 0,
  pageLocation,
}) => {
  let MenuItems = []

  const rows = [...Array(Math.ceil(mainNavigation.length / itemsPerColumn))]
  const menuRows = rows.map((row, idx) =>
    mainNavigation.slice(
      idx * itemsPerColumn,
      idx * itemsPerColumn + itemsPerColumn
    )
  )

  const MenuItem = (menuItem, index) => {
    const isParentOnSubPage =
      level == 0 &&
      menuItem.submenu &&
      menuItem.submenu[0].menuItems.filter(({ subMenuSection, url }) => {
        return (
          url == pageLocation ||
          (subMenuSection &&
            subMenuSection[0].menuItems.filter(({ url }) => url == pageLocation)
              .length > 0) || (pageLocation.includes('blog') && url.includes('blog'))
        )
      }).length > 0

    return (
      <MenuLi
        key={index}
        className={
          (menuItem.submenu ? 'has-submenu ' : '') +
          (menuItem && menuItem.cssClasses ? menuItem.cssClasses : '') +
          (menuItem.subMenuSection ? ' has-subsection' : '')
        }
      >
        <Link
          to={menuItem.url ? menuItem.url : '#'}
          title={menuItem.title}
          activeClassName={'active'}
          partiallyActive={menuItem.url != '/'}
          className={`hover:color-primary ${isParentOnSubPage ? 'active' : ''}`}
        >
          {menuItem.name}
        </Link>

        {/* render submenu */}
        {menuItem && menuItem.submenu && (
          <SubMenu
            className={`submenu bg-graylight ${
              Math.ceil(menuItem.submenu[0].menuItems.length / itemsPerColumn) >
              1
                ? 'row'
                : ''
            }`}
          >
            <MainNavigation
              mainNavigation={menuItem.submenu[0].menuItems}
              level={level + 1}
            />
          </SubMenu>
        )}
        {menuItem && menuItem.subMenuSection && (
          <SubSectionWrapper
            ref={refCallback && refCallback(menuItem.name, index)}
          >
            {menuItem.subMenuSection.map(section => (
              <SubSection
                className="subsection bg-color-primary-lg"
                key={section.id}
              >
                <MainNavigation
                  mainNavigation={section.menuItems}
                  level={level + 1}
                />
              </SubSection>
            ))}
          </SubSectionWrapper>
        )}
      </MenuLi>
    )
  }

  MenuItems = menuRows.map((row, i) => {
    return (
      <ColumnWrapper
        columns={
          level > 0 && Math.ceil(mainNavigation.length / itemsPerColumn) > 1
        }
        key={i}
      >
        {row.map(MenuItem)}
      </ColumnWrapper>
    )
  })
  return MenuItems
}

const CallToAction = props => {
  const popUpHandler = close => (
    <div className="p-2 block">
      <button
        style={{ borderWidth: '0', float: 'right' }}
        className="cursor-pointer d-block   mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
        onClick={() => {
          close()
        }}
      >
        <X color={'#333'} strokeWidth={'3'} className="d-block" />
      </button>
      <div className="p-3 py-4 block">
        <h2 className="mb-1 popupheading" style={{ lineHeight: '1.05' }}>
        Get Started Today!
        </h2>
        <p
          style={{
            color: '#666',
            lineHeight: '1.5',
            fontSize: '.9em',
          }}
          className="mb-3 popupdesc"
        >
          Interested in learning more about our new home construction process? We're here to help. Simply give us a call or fill out this form on our website and we'll take the time to learn all of the details of your dream home.
        </p>
        <ContactForm
          showServicesList={true}
          showLabels={true}
          showPlaceHolders={true}
          pageLocation={
            props.pageProps.location.href
              ? props.pageProps.location.href
              : 'Not provided'
          }
        />
      </div>
    </div>
  )

  return (
    <CTASectionWrapper>
      <div className="headeroneph">
        <div className="flex items-center">
          
           
            <PhoneNumberHeader
              href={'tel:' + props.companyInfo.phone}
              className="hover:text-color-primary mr-4 items-center"
            >
              {props.companyInfo.phone}
            </PhoneNumberHeader>
         

          <div className="column divcentervertical estbuttonalign">
            <Popup
              trigger={
                <button
                  title={'Contact Us'}
                  style={{ borderWidth: '0' }}
                  className="border-radius button-style-primary button-style-white-outline border-0 cursor-pointer primary cta-button"
                >
                  Contact Us
                </button>
              }
              modal
              closeOnDocumentClick
            >
              {popUpHandler}
            </Popup>
          </div>
        </div>
      </div>
    </CTASectionWrapper>
  )
}

class Header extends React.Component {
  state = {
    headerOpen: false,
    windowWidth: windowWidth,
    defaultMenu: this.props.mainNavigation,
  }

  subSectionRefs = {}

  componentDidMount() {
    this.updatePhoneLoadScrollReveal()
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', this.handleWindowResize)
    }
    const { campaigns = 'default' } = getValueTrackingParams()
    if (campaigns != 'default') {
      const matchingCampaignMenu = this.props.menus.filter(
        ({ node: { campaign } }) =>
          campaign.length > 0 ? campaign[0] == campaigns : false
      )

      if (matchingCampaignMenu.length > 0) {
        this.setState({
          ...this.state,
          defaultMenu: matchingCampaignMenu[0].node.menuItems,
        })
      }
    }
  }

  componentDidUpdate() {
    this.updatePhoneLoadScrollReveal()
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleWindowResize)
    }
  }

  handleWindowResize = () => {
    if (typeof window !== `undefined`) {
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  updatePhoneLoadScrollReveal = () => {
    try {
      window.CallTrk.swap()
    } catch (e) {
      console.log('phone swap blocked')
    }
    // window.ScrollReveal().reveal('.hero-title, .hero-paragraph, .hero-cta', {
    //   duration: 1e3,
    //   distance: '40px',
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   origin: 'bottom',
    //   interval: 150,
    // })
    // window.ScrollReveal().reveal('.feature, .pricing-table', {
    //   duration: 600,
    //   distance: '40px',
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   interval: 100,
    //   origin: 'bottom',
    //   viewFactor: 0.5,
    // })
    // window.ScrollReveal().reveal('.feature-extended-image', {
    //   duration: 600,
    //   scale: 0.9,
    //   interval: 100,
    //   easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
    //   viewFactor: 0.5,
    // })
  }

  toggleMobileMenuOverlay = e => {
    e.preventDefault()
    this.setState({
      headerOpen: (this.headerOpen = !this.headerOpen),
    })

    if (document.body.classList.contains('mobile-menu-overlay--open')) {
      document.body.classList.remove('mobile-menu-overlay--open')
    } else {
      document.body.classList.add('mobile-menu-overlay--open')
    }
  }

  refCallback = name => ref => {
    if (ref && typeof window !== `undefined`) {
      this.subSectionRefs[name] = ref
      if (
        this.subSectionRefs[name].getBoundingClientRect().right >
        window.innerWidth
      ) {
        this.subSectionRefs[name].classList.add('off-right')
      } else {
        this.subSectionRefs[name].classList.remove('off-right')
      }
    }
  }

  render() {
    return (
      <>
        <HeaderOuterContainer className="container-fluid">
          <div className="container padding-0  bg-color-header-inner">
            <div className="row">
              <LogoContainer className="py-3 col-4 col-lg-3 d-flex align-items-center">
                <Link to="/" style={{ lineHeight: '0', padding: '0'}}>
                  <LogoSVG alt={this.props.siteTitle + ' Logo'}>
                    <div className="lg:hidden">
                      <Logo horizontal={true} color="dark"/>
                    </div>
                    <div className="hidden lg:block">
                    <Logo color="dark" />
                    </div>
                
                  </LogoSVG>
                </Link>
              </LogoContainer>

              <div className="col-8 col-lg-9 flex flex-col justify-content-center align-items-end-mobile justify-content-lg-end md:justify-content-end p-0">

<div className="flex pb-4 pt-4">
{this.props.siteDescription ? (
                <SiteTagline className="sitetaglinehead divcentervertical d-xl-flex col-5">
                  <p className="sitelinehead">
                    Eco-Friendly Custom Home Builder
                  </p>
                  <p className="areasservedheader">
                    {this.props &&
                    this.props.siteDescription ==
                      'Serving Sacramento & Surrounding' &&
                    this.props.pageProps.path.includes('blog')
                      ? personalizedServingText(this.props.pageProps.path)
                      : this.props.siteDescription}
                  </p>
                </SiteTagline>
              ) : null}

              <div className="d-none d-lg-flex align-items-center col-7 justify-content-end">
                <CallToAction {...this.props} />
              </div>

              <MenuMobile className="d-lg-none d-xl-none col-12 d-flex align-items-center justify-content-end">
              <button
                    type="button"
                    onClick={this.toggleMobileMenuOverlay}
                    className=" bg-white border-none cursor-pointer duration-150 ease-in focus:bg-gray-50 focus:outline-none focus:text-gray-700 hover:bg-white p-2 rounded-lg text-center text-gray-600 transition"
                  >{this.state.headerOpen ? <svg
                    className="h-6 w-6 mx-auto"
                    x-description="Heroicon name: x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg> :  <svg
                      className="h-6 w-6 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>}
                   
                    <span className="block uppercase font-semibold" style={{fontSize:'.5rem'}}>{this.state.headerOpen ? 'Close' : 'Menu'}</span>
                  </button>
 
              </MenuMobile>
</div>
              <NavOuterContainer className="menu-desktop d-none d-lg-flex align-items-center">
          <div className="container">
            <ul className="justify-content-end">
              <MainNavigation
                pageLocation={this.props.pageProps.location.pathname}
                mainNavigation={this.state.defaultMenu}
                refCallback={this.refCallback}
              />
            </ul>
          </div>
          
        </NavOuterContainer>

              </div>

         
            </div>
          </div>

          <SiteTagline
            className={`mobileareaserved container padding-0 ${
              !this.props.pageProps.path.includes('blog') ? 'hidden' : ''
            }`}
          >
            <p>
              {this.props &&
              this.props.siteDescription == 'Serving Sacramento & Surrounding' &&
              this.props.pageProps.path.includes('blog')
                ? personalizedServingText(this.props.pageProps.path)
                : this.props.siteDescription}
            </p>
          </SiteTagline>


        </HeaderOuterContainer>

       

        <StickyContainer>
          <MobileSecondaryCTA className="d-lg-none d-xl-none">
            <div className="container">
              <CallToAction
                {...this.props}
                className="d-lg-none d-xl-none col-6 d-flex align-items-center"
              />
            </div>
          </MobileSecondaryCTA>
        </StickyContainer>
        <div
          className={'mobileOverlay' + (this.state.headerOpen ? ' open' : '')}
        >
          <div className="animate-overlay-content">
            <NavigationMobileUL>
              <MainNavigation
                mainNavigation={this.state.defaultMenu}
                pageLocation={this.props.pageProps.location.pathname}
              />
            </NavigationMobileUL>
          </div>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  mainNavigation: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: '',
  siteDescription: '',
  mainNavigation: '',
}

export default Header
